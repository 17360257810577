import React, { useEffect, useState } from 'react';
import './style.scss';
import Lottie from "lottie-react"
import BalcaoVirtualService from '../../../services/BalcaoVirtualService';
import ConsultarService from '../../../services/ConsultarService';
import { callType } from '..';

const waitingLottie = require('../../../assets/lotties/waiting.json');
const callLottie = require('../../../assets/lotties/call.json');

type LoadingProps = {
  balcaoId: string;
  onAccept: (document: string) => void;
  playRing: () => void;
  type: callType;
  setOnCall: () => void;
  setName: (name: string) => void;
}

export function Loading(props: LoadingProps) {
  const [contributor, setContributor] = useState<string | undefined>();
  const [document, setDocument] = useState<string>('');
  useEffect(() => {
    loadBalcao();
  }, []);

  useEffect(() => {
    if (contributor && props.type !== 'contributor') {
      props.playRing();
    }
  }, [contributor]);

  const loadBalcao = () => {
    BalcaoVirtualService.balcaoPorID(props.balcaoId)
      .then((res) => {
        if (props.type === 'attendant') {
          if (res.contributor_document === null && props.type === 'attendant') {
            setTimeout(() => {
              loadBalcao();
            }, 5000);
          } else {
            ConsultarService.contributor({contributor_document: res.contributor_document})
              .then((contributorResponse) => {
                setContributor(contributorResponse.data.name);
                setDocument(contributorResponse.data.cpf ? contributorResponse.data.cpf : contributorResponse.data.cnpj)
              })
          }
        } else {
          if (res.on_call && res.on_attendant) {
            props.setOnCall();
          } else {
            setTimeout(() => {
              loadBalcao();
            }, 3000);
          }
        }
        const document = JSON.parse(res.contributor_document as string);
        if (res.contributor_document === null) {
          setTimeout(() => {
            loadBalcao();
          }, 5000);
        } else {
          setDocument(document[0]);
          ConsultarService.contributor({contributor_document: document[0]})
            .then((contributorResponse) => {
              setDocument(contributorResponse.data.cpf ? contributorResponse.data.cpf : contributorResponse.data.cnpj)
            })
          BalcaoVirtualService.getServiceName(props.balcaoId)
          .then((res) => {
            setContributor(res.attendance_name);
            props.setName(res.attendance_name);
          })
        }
      });
  }

  const renderCall = () => {
    props.onAccept(document)
  }

  const renderWaiting = () => {
    if (props.type === 'contributor') {
      return renderWaitingToContributor();
    }
    return (
      <div className='loadingContainer'>
        <Lottie
          animationData={waitingLottie}
          autoplay={true}
          loop={true}
          className='lottie'
        />
        <span className='loadingText'>
          Por favor, aguarde. A qualquer momento um contribuinte poderá solicitar atendimento.
        </span>
      </div>
    )
  }

  const renderWaitingToContributor = () => {
    return (
      <div className='loadingContainer'>
        <Lottie
          animationData={waitingLottie}
          autoplay={true}
          loop={true}
          className='lottie'
        />
        <span className='loadingText'>
          Por favor, aguarde. A qualquer momento um de nossos colaboradores irá te atender.
        </span>

        <span className='loadingTextWarning'>
          Na próxima tela, libere o microfone e a câmera.
        </span>
      </div>
    )
  }

  return (
    <>
      {contributor && props.type === 'attendant' ? renderCall() : renderWaiting()}
    </>
  );
}
